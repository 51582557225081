////////////////////////////////////////////////////////////////////
//
// CUSTOM DVIEW ICONS
// - These can be used with <ion-icon :icon="...">
//
////////////////////////////////////////////////////////////////////

// TODO!! UX should recreate this SVG as it does not conform to a rectangular 512x512 viewbox, which also affects default Ionic stroke-width of 32px
export const deviationCompleted = `
    data:image/svg+xml;utf8,<svg width="26" height="29" viewBox="0 0 26 29" class="ionicon" xmlns="http://www.w3.org/2000/svg">
        <path class="ionicon-stroke-width" fill-rule="evenodd" clip-rule="evenodd" d="M17.0733 2.9H23.1111C24.7 2.9 26 4.205 26 5.8V26.1C26 27.695 24.7 29 23.1111 29H2.88889C2.68667 29 2.49889 28.9855 2.31111 28.9565C1.74778 28.8405 1.24222 28.5505 0.852222 28.159C0.592222 27.8835 0.375556 27.579 0.231111 27.231C0.0866667 26.883 0 26.4915 0 26.1V5.8C0 5.394 0.0866667 5.017 0.231111 4.6835C0.375556 4.3355 0.592222 4.0165 0.852222 3.7555C1.24222 3.364 1.74778 3.074 2.31111 2.958C2.49889 2.9145 2.68667 2.9 2.88889 2.9H8.92667C9.53333 1.218 11.1222 0 13 0C14.8778 0 16.4667 1.218 17.0733 2.9ZM19.63 9.54103L21.6666 11.6L10.1111 23.2L4.33331 17.4L6.36998 15.3555L10.1111 19.0965L19.63 9.54103ZM13 2.53751C13.5922 2.53751 14.0834 3.03051 14.0834 3.62501C14.0834 4.21951 13.5922 4.71251 13 4.71251C12.4078 4.71251 11.9167 4.21951 11.9167 3.62501C11.9167 3.03051 12.4078 2.53751 13 2.53751ZM2.88885 26.1H23.1111V5.80001H2.88885V26.1Z"/>
    </svg>
`

// TODO!! UX should recreate this SVG as it does not conform to a rectangular 512x512 viewbox, which also affects default Ionic stroke-width of 32px
export const deviationOpen = `
    data:image/svg+xml;utf8,<svg width="27" height="29" viewBox="0 0 27 29" class="ionicon" xmlns="http://www.w3.org/2000/svg">
        <path class="ionicon-stroke-width" fill-rule="evenodd" clip-rule="evenodd" d="M17.139 2.9H23.2C24.795 2.9 26.1 4.205 26.1 5.8V26.1C26.1 27.695 24.795 29 23.2 29H2.9C2.697 29 2.5085 28.9855 2.32 28.9565C1.7545 28.8405 1.247 28.5505 0.8555 28.159C0.5945 27.8835 0.377 27.579 0.232 27.231C0.087 26.883 0 26.4915 0 26.1V5.8C0 5.394 0.087 5.017 0.232 4.6835C0.377 4.3355 0.5945 4.0165 0.8555 3.7555C1.247 3.364 1.7545 3.074 2.32 2.958C2.5085 2.9145 2.697 2.9 2.9 2.9H8.961C9.57 1.218 11.165 0 13.05 0C14.935 0 16.53 1.218 17.139 2.9ZM11.5999 8.7H14.4999V17.4H11.5999V8.7ZM11.5999 20.3H14.4999V23.2H11.5999V20.3ZM13.05 2.53751C13.6445 2.53751 14.1375 3.03051 14.1375 3.62501C14.1375 4.21951 13.6445 4.71251 13.05 4.71251C12.4555 4.71251 11.9625 4.21951 11.9625 3.62501C11.9625 3.03051 12.4555 2.53751 13.05 2.53751ZM2.89996 26.1H23.2V5.80002H2.89996V26.1Z"/>
    </svg>
`

export const errorLinkBroken = `
    data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g>
            <g>
                <path d="M237.273,323.149c-8.13-4.563-18.415-1.688-22.989,6.431c-3.196,5.688-8.023,9.655-14.775,12.142L66.237,391.008
                    l-27.665-74.685l133.171-49.242c7.573-2.751,16.148-2.25,22.939,1.322c8.254,4.355,18.454,1.182,22.798-7.072
                    c4.344-8.248,1.182-18.455-7.067-22.798c-15.298-8.057-33.601-9.216-50.289-3.151L11.025,290.521
                    c-4.203,1.553-7.607,4.709-9.48,8.783c-1.874,4.068-2.048,8.715-0.495,12.912l39.385,106.339
                    c2.521,6.808,8.974,11.022,15.833,11.022c1.941,0,3.916-0.343,5.851-1.058L211.2,373.386
                    c14.167-5.221,25.409-14.645,32.504-27.249C248.278,338.013,245.397,327.723,237.273,323.149z"></path>
            </g>
        </g>
        <g>
            <g>
                <path d="M508.329,261.529c-2.791-3.505-6.859-5.756-11.303-6.262L339.11,237.279c-18.173-2.251-36.268,3.657-49.67,16.271
                    c-6.791,6.386-7.117,17.065-0.726,23.856c6.38,6.791,17.059,7.123,23.856,0.726c6.144-5.778,14.117-8.383,22.534-7.337
                    l141.323,16.091l-9.103,79.551l-141.661-16.125c-8.929-0.85-14.764-5.598-18.089-9.441c-6.11-7.039-16.767-7.798-23.811-1.705
                    c-7.044,6.105-7.809,16.767-1.705,23.811c10.414,12.018,24.767,19.45,40.099,20.902l158.107,18.004
                    c0.647,0.079,1.3,0.113,1.935,0.113c8.462,0,15.765-6.352,16.744-14.955l12.941-113.09
                    C512.397,269.501,511.114,265.034,508.329,261.529z"></path>
            </g>
        </g>
        <g>
            <g>
                <path d="M260.696,82.418c-0.068,0-0.135,0-0.203,0c-9.227,0-16.761,7.427-16.873,16.682l-1.125,95.648
                    c-0.107,9.323,7.359,16.964,16.682,17.076c0.068,0,0.135,0,0.203,0c9.227,0,16.767-7.421,16.873-16.682l1.125-95.648
                    C277.485,90.172,270.018,82.531,260.696,82.418z"></path>
            </g>
        </g>
        <g>
            <g>
                <path d="M212.089,184.233l-34.321-45.574c-5.604-7.449-16.181-8.946-23.636-3.331c-7.449,5.604-8.94,16.187-3.331,23.636
                    l34.321,45.574c3.32,4.405,8.378,6.724,13.498,6.724c3.533,0,7.1-1.103,10.139-3.393
                    C216.208,202.266,217.699,191.683,212.089,184.233z"></path>
            </g>
        </g>
        <g>
            <g>
                <path d="M366.297,135.323c-7.455-5.609-18.027-4.119-23.636,3.331l-34.321,45.574c-5.609,7.449-4.113,18.027,3.331,23.636
                    c3.044,2.296,6.605,3.398,10.144,3.398c5.12,0,10.178-2.318,13.492-6.729l34.321-45.574
                    C375.237,151.51,373.741,140.933,366.297,135.323z"></path>
            </g>
        </g>
    </svg>
`

export const information = `
    data:image/svg+xml;utf8,<svg width="512" height="512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M256 35.7209C134.343 35.7209 35.7209 134.343 35.7209 256C35.7209 377.657 134.343 476.279 256 476.279C377.657 476.279 476.279 377.657 476.279 256C476.279 134.343 377.657 35.7209 256 35.7209ZM0 256C0 114.615 114.615 0 256 0C397.385 0 512 114.615 512 256C512 397.385 397.385 512 256 512C114.615 512 0 397.385 0 256ZM256 142.884C265.864 142.884 273.86 150.88 273.86 160.744V170.95C273.86 180.814 265.864 188.811 256 188.811C246.136 188.811 238.14 180.814 238.14 170.95V160.744C238.14 150.88 246.136 142.884 256 142.884ZM256 227.934C265.864 227.934 273.86 235.93 273.86 245.794V327.442C273.86 337.306 265.864 345.302 256 345.302C246.136 345.302 238.14 337.306 238.14 327.442V245.794C238.14 235.93 246.136 227.934 256 227.934Z" />
    </svg>
`