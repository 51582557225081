
import { defineComponent, onMounted } from 'vue'
import { Capacitor } from '@capacitor/core'
import { homeOutline } from 'ionicons/icons'
import { deviationCompleted, deviationOpen } from '@dview/shared/icons'
import { useUserPreferences, useNative, usePushNotification, NativePermission } from '@dview/logic'
import { NotificationMode } from '@dview/shared/models'

export default defineComponent({
    setup() {
        onMounted(() => {
            showPushNotificationPrompt()
        })

        return {
            deviationOverviewIcon: homeOutline,
            deviationOpenIcon: deviationOpen,
            deviationCompletedIcon: deviationCompleted,
        }
    },
})

/**
 * Show a push notification permission prompt for users, who have enabled notifications
 * but not yet accepted push notification permissions.
 */
async function showPushNotificationPrompt() {
    if (!Capacitor.isNativePlatform()) {
        return
    }

    const { preferences } = useUserPreferences()
    const { hasAllowedPermission } = useNative()
    const { registerDevice } = usePushNotification()

    const permission = await hasAllowedPermission(NativePermission.PushNotifications)
    if (!permission && preferences.notifications.mode === NotificationMode.Daily) {
        await registerDevice()
    }
}
